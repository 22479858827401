import Vue from 'vue'
import VueRouter from 'vue-router'
import lanhu_huodongye from '../views/lanhu_huodongye/index.vue'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        component: lanhu_huodongye
        // redirect: "/lanhu_huodongye"
    },
    // {
    //     path: '/lanhu_huodongye',
    //     name: 'lanhu_huodongye',
    //     component: lanhu_huodongye
    // }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router
